module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gálos Ádám - Doboktatás","short_name":"Doboktatás","start_url":"/","background_color":"#4E00B3","theme_color":"#4E00B3","display":"minimal-ui","icon":"src/images/dob.png","icons":[{"src":"/favicons/dob.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"30fed28a4f6e6ebfd5f0d200ab704773"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
